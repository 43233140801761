
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

.fontUbuntu {
    font-family: 'Ubuntu';
}

h1.page-title-p {
    font-size: 50px;
    font-family: 'Ubuntu';
}

span.text-button {
    text-decoration: underline;
}

span.text-button:hover {
    cursor: pointer;
}

a.text-link{
    color: inherit;
}

div.notification {
    position: fixed;
    top: 70px;
    right: 10px;
    z-index: 40;
    max-width: 50%;
}

.title {
    font-family: 'Ubuntu';
    font-size: 70px;
    margin: 0;
    padding: 0;
}

.title.big {
    font-size: 50px;
    margin: 0;
    padding: 0;
}

.title.middle {
    font-size: 30px;
}

.title.small {
    font-size: 20px;
}

.title.very-small {
    font-size: 15px;
}

.payments-table-row {
    transition: all .2s;
}

.payments-table-row:hover {
    cursor: pointer;
    background-color: #00000015;
}

html {
    height: -webkit-fill-available;
}

body {
    height: -webkit-fill-available;
}

div#root {
    height: -webkit-fill-available;
}

div.full-height {
    height: -webkit-fill-available;
}

div.full-height.with-navbar {
    height: calc(100% - 75px);
}

div.icon-text {
    text-align: center;
    max-width: 120px;
    margin: 6px;
    transition: all .3s;
}

div.icon-text:hover {
    cursor: pointer;
    transform: scale(1.1);
}

div.notification {
    position: fixed;
    top: 70px;
    right: 10px;
    z-index: 40;
    max-width: 50%;
    min-width: 150px;
    border: 2px solid var(--bordercolor);
    border-radius: 7px;
}

div.presu-box {
    border: 2px solid #777777;
    border-radius: 8px;
    padding: 5px;
}