div.item-card {
    margin: 10px;
    min-height: 100px;
    background-color: rgb(229, 229, 229);
    border-radius: 5px;
    transition: all .2s;
}

div.item-card:hover {
    transform: scale(1.015);
    cursor: pointer;
}

div.item-card.grid {
    display: grid;
    grid-template-areas: "photo name name qnty price"
                         "photo type type qnty price";
    grid-template-columns: 10% 30% 30% 10% 20%;
    grid-template-rows: min-content;
}

.item-photo {
    grid-area: photo;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    object-fit: cover;
    border-radius: 5px;
}

div.item-name {
    grid-area: name;
    padding: 5px 15px 0px 15px;
    font-size: 20px;
    font-weight: 700;
}

div.item-data {
    grid-area: type;
    padding: 0px 15px;
}

div.item-qnt {
    grid-area: qnty;
    padding: 0px 5px;
    text-align: center;
    margin: auto;
    font-size: 3vw;
}

div.item-prices {
    grid-area: price;
    padding: 5px;
    text-align: end;
    display: flex;
    flex-flow: column;
}

div.separated-field {
    margin: auto 0px auto 0px;
}