@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

div.home-page-carrousel {
    background-color: #708090;
    border-radius: 0px 0px 30px 30px;
}

div.carousel-course-region {
    width: -webkit-fill-available;
    display: flex;
    min-height: var(--minHeighDynamic);
}

div.carousel-course-region.no-flex {
    width: -webkit-fill-available;
    display: block;
    min-height: 500px;
}

div.carousel-course-presentation {
    padding: 50px 20px 50px 100px;
    font-family: 'Ubuntu';
    width: 50%;
    font-size: 40px;
    font-weight: 600;
}

div.carousel-course-presentation.no-flex {
    padding: 50px 100px;
    font-family: 'Ubuntu';
    width: 100%;
    font-size: 40px;
    font-weight: 600;
}

img.carousel-presentation-img {
    width: 100%;
    object-fit: cover;
    border-radius: 0px 0px 30px 0px;
    height: 100%;
    object-position: left
}

img.carousel-presentation-img.no-flex {
    max-height: 220px;
    width: 100%;
    object-fit: cover;
    border-radius: 0px;
}

div.carousel-extra-info {
    display: flex;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}

div.carousel-extra-info.no-flex {
    display: flex;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    flex-flow: column;
    align-items: center;
}

div.carousel-extra-info .important-text {
    font-size: 28px;
    font-weight: 700;
}

div.important-text {
    font-size: 28px;
    font-weight: 700;
}

div.carousel-extra-info .data-part {
    width: 30%;
}

div.carousel-extra-info .data-part.no-flex {
    width: -webkit-fill-available;
}

div.no-center-align {
    text-align: justify;
}