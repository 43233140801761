div.page-button {
    background-image: linear-gradient(45deg, rgba(54,69,79,1) 0%, rgba(112,128,144,1) 100%);
    color: white;
    border-radius: 5px;
    width: 220px;
    height: 125px;
    margin: 5px;
    border: 2px solid transparent;
    transition: all 0.3s;
}

div.page-button:hover {
    cursor: pointer;
    box-shadow: 0px 0px 5px 1px #36454f;
    transform: scale(1.2);
    border: 2px solid #36454f;
    z-index: 30;
}

div.button-box-title {
    color: white;
    font-size: 22px;
    font-weight: 600;
    line-height: 24px;
}

div.button-box-description {
    color: white;
    text-align: justify;
    font-size: 14px;
}
